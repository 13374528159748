import { Placement } from '@floating-ui/core/src/types'
import { Float } from '@headlessui-float/react'
import { Popover as PopoverHeadless } from '@headlessui/react'
import React, { ReactNode } from 'react'

export interface PopoverProps {
  children: (open: boolean) => ReactNode
  label: string | JSX.Element
  position?: Placement
  className?: string
  popoverClassName?: string
}

const Popover = ({ children, label, position, className, popoverClassName }: PopoverProps) => (
  <PopoverHeadless className={'flex items-center'}>
    {({ open }) => (
      <Float
        portal
        placement={position || 'top'}
        offset={16}
        flip={{
          fallbackPlacements: [
            'top',
            'bottom',
            'left',
            'right',
            'top-end',
            'top-start',
            'right-end',
            'right-start',
            'bottom-end',
            'bottom-start',
            'left-end',
            'left-start',
          ],
          fallbackStrategy: 'initialPlacement',
        }}
        arrow
        enter="transition duration-100 ease-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-75 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <PopoverHeadless.Button
          className={`relative truncate flex items-center cursor-pointer [&>*]:main-transition-colors  ${
            className ? className : 'w-full max-w-fit h-full '
          } outline-none group`}
        >
          {children(open)}
        </PopoverHeadless.Button>
        <PopoverHeadless.Panel className={`shadow-3xl rounded-lg z-50 ${popoverClassName || ''}`}>
          <Float.Arrow className="absolute bg-white w-5 h-5 rotate-45 border border-white" />
          <div className={`relative whitespace-normal px-5 py-3 bg-white rounded-lg`}>
            <div className="w-full text-center text-gray-300 font-medium text-sm font-averta break-normal">
              {label}
            </div>
          </div>
        </PopoverHeadless.Panel>
      </Float>
    )}
  </PopoverHeadless>
)

export default Popover
