import Router, { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePaginationQuery } from 'shared/route-query/hooks/use-pagination-query'
import { getPaginationQuery } from 'shared/route-query/utils/get-pagination-query'
import { setPaginationQuery } from 'shared/route-query/utils/set-pagination-query'
import { LimitType, OrderEnum, PaginationInterface } from 'shared/types/pagination-interface'

const paginationKeys = ['order', 'limit', 'startFromId']

export const usePagination = (isStaticUrl?: boolean) => {
  const { isReady, query } = useRouter()

  const queryRef = useRef(query)

  useEffect(() => {
    queryRef.current = query
  }, [query])

  const { limit: limitFromRouter, startFromId, order } = usePaginationQuery()

  const [limit, setLimit] = useState<LimitType>(limitFromRouter || 10)

  const [pagination, setPagination] = useState<PaginationInterface>({
    startFromId: startFromId,
    order: OrderEnum.next,
  })

  useEffect(() => {
    if (!isReady) return
    setPaginationQuery({ setPagination, setLimit, limit: limitFromRouter, order, startFromId })
  }, [isReady, limitFromRouter, order, startFromId])

  useEffect(() => {
    if (isStaticUrl || !isReady) return

    const paginationQuery = getPaginationQuery({ limit, pagination })

    const baseQueryKeys = (Object.keys(queryRef.current) as Array<string>).filter(
      el => !paginationKeys.includes(el),
    )

    const baseQuery: Record<string, string | string[] | undefined> = {}
    baseQueryKeys.forEach(key => {
      baseQuery[key] = queryRef.current[key]
    })

    const timeout = setTimeout(() => {
      Router.replace(
        {
          query: {
            ...baseQuery,
            ...paginationQuery,
          },
        },
        undefined,
        { shallow: true },
      )
    })

    return function cleanUp() {
      clearTimeout(timeout)
    }
  }, [isStaticUrl, limit, isReady, pagination])

  const clearPagination = useCallback(() => {
    setPagination({ startFromId: undefined, order: OrderEnum.next })
    setLimit(10)
  }, [])

  return { limit, setLimit, pagination, setPagination, clearPagination }
}
